// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiRest: {
    base: 'https://apirest.io',
    pharmacies: '/pharmacies',
    newspapers: '/newspapers'
  },
  tv2WpApi: {
    base: 'https://cms.tv2noticias.com/wp-json',
    ads: '/tv2/v2/publicidades',
    configuration: '/tv2/v2/configuraciones',
    portada: '/tv2/v2/portada',
    categories: '/wp/v2/categories',
    getPostsByCategoryId: '/wp/v2/posts?categories={id}&_embed',
    getPostBySlug: '/wp/v2/posts?slug={slug}&_embed',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
